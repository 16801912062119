import cx from 'classnames';
import React from 'react';
import dynamic from 'next/dynamic';
import { ILayoutBaseProps } from '@/interfaces/jsx.interface';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import styles from './styles.module.less';

export interface IProps extends ILayoutBaseProps {
  disableHeader?: boolean;
  disableFooter?: boolean;
  haveFooter: boolean;
}

export const MasterLayout: React.FC<IProps> = (props) => {
  const { uid } = useGetUserInfo();
  const NewFeedback = dynamic(
    () => import('@digit-fe/digit-component/dist/Feedback/index'),
    {
      ssr: false,
    },
  );
  return (
    <>
      <div
        className={cx(
          styles['layout-container'],
          props.haveFooter && styles.layout_wrapper,
        )}
      >
        <props.mainComp
          router={props.routeProps}
          pageProps={props.pageProps}
          {...props.pageProps}
        />
        <NewFeedback
          popoverData={(defaultData) => {
            console.log('defaultData是');
            console.log(defaultData);
            const data = defaultData.slice();
            data.splice(0, 1);
            const secondItem = data[1];
            const third = data[2];
            data.splice(1, 1, {
              ...secondItem,
              extra: (
                <div>
                  <img
                    alt="qr"
                    src="https://hwobs.fanruan.com/ui/digit-fe/feedback/isaac_qr.png"
                    width={80}
                  />
                </div>
              ),
              title: '考试咨询',
              subTitle: '报名、考试、阅卷有关问题',
              info: '请扫码加右侧微信',
            });
            data.splice(2, 1, {
              ...third,
              subTitle: '认证考试紧急热线：18291881725',
            });
            return data;
          }}
          uid={uid}
          env={
            process.env.NEXT_PUBLIC_ENVIRONMENT_ENV === 'prod' ? 'prod' : 'test'
          }
        />
        {/*<BackTop visibilityHeight={50} className={styles.back_to_top_wrapper}>*/}
        {/*  <div className={styles.back_to_top}>*/}
        {/*    <img alt="" src="/img/arrow_top.png" width="20" height="20" />*/}
        {/*  </div>*/}
        {/*</BackTop>*/}
      </div>
    </>
  );
};
